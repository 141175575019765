import React, { useEffect, useState } from "react";
import "./allproduct.css";
import ProductCard from "./ProductCard";
import book1 from "../../assets/book1.jpg";
import book3 from "../../assets/book3.jpg";
import book2 from "../../assets/book2.jpg";
import cycle1 from "../../assets/cycle1.jpg";
import cycle2 from "../../assets/cycle2.jpg";
import democycle from "../../assets/democycle.png";
import diya from "../../assets/diya.png";
import diya1 from "../../assets/diya1.jpg";
import diya2 from "../../assets/diya2.jpg";
import viskitbook from "../../assets/viskitbook.jpg";
import herbalproduct from "../../assets/herbalproduct.png";
import demobook from "../../assets/demobook.png";
import demobook1 from "../../assets/demobook1.png";
import herbalproduct1 from "../../assets/herbalproduct1.png";
import herbalproduct2 from "../../assets/herbalproduct2.png";
import axios from 'axios';

const AllProducts = () => {
  const products = [
    {
      ProductId: 1,
      ProductName: "Role of Academic-Driven Startups in Economy",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: book1,
        },
        {
          id: 2,
          image: demobook,
        },
        {
          id: 3,
          image: demobook1,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 500,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 2,
      ProductName: "Recent Advances in School Education",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: book2,
        },
        {
          id: 2,
          image: demobook,
        },
        {
          id: 3,
          image: demobook1,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 500 ,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 3,
      ProductName: "Siksha MahaKumbh",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: book3,
        },
        {
          id: 2,
          image: demobook,
        },
        {
          id: 3,
          image: demobook1,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 500,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 4,
      ProductName: "E-Cycle",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: cycle1,
        },
        {
          id: 2,
          image: cycle2,
        },
        {
          id: 3,
          image: democycle,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 40000,
      SalesPrice: 38000,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 5,
      ProductName: "E-cycle",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: cycle2,
        },
        {
          id: 2,
          image: cycle1,
        },
        {
          id: 3,
          image: democycle,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 40000,
      SalesPrice: 38000,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 6,
      ProductName: "Gobar Diya",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: diya,
        },
        {
          id: 2,
          image: diya1,
        },
        {
          id: 3,
          image: diya2,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 100,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 7,
      ProductName: "Shurbhi Diya",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: diya1,
        },
        {
          id: 2,
          image: diya2,
        },
        {
          id: 3,
          image: diya,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 100,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 8,
      ProductName: "Gulak",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: diya2,
        },
        {
          id: 2,
          image: diya,
        },
        {
          id: 3,
          image: diya1,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 100,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 9,
      ProductName: "Viksit Bharat Journal",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: viskitbook,
        },
        {
          id: 2,
          image: demobook,
        },
        {
          id: 3,
          image: demobook1,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 500,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
    {
      ProductId: 10,
      ProductName: "Herbal Products",
      ProductDescription:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      ProductImage: [
        {
          id: 1,
          image: herbalproduct,
        },
        {
          id: 2,
          image: herbalproduct1,
        },
        {
          id: 3,
          image: herbalproduct2,
        },
      ],
      ProductCode: "P1",
      ProductCategory: "Category 1",
      ProductSubCategory: "Sub Category 1",
      ProductBrand: "Brand 1",
      ProductColor: "Color 1",
      ProductSize: "Size 1",
      ProductWeight: "Weight 1",
      ProductMaterial: "Material 1",
      ProductQuantity: 10,
      ProductUnit: "Unit 1",
      ProductPrice: 100,
      SalesPrice: 80,
      ProductDiscount: 20,
      ProductDiscountType: "Percentage",
      ProductTax: 20,
      ProductTaxType: "Percentage",
      ProductShippingCharge: 20,
      ProductShippingChargeType: "Percentage",
      ProductShippingTime: "1-2 days",
      ProductShippingTimeType: "Days",
      ProductShippingLocation: "Location 1",
      ProductShippingLocationType: "Country",
      ProductShippingReturnPolicy: "Return Policy 1",
      ProductShippingReturnPolicyType: "Days",
      ProductShippingReturnPolicyDescription: "Return Policy Description 1",
      ProductShippingReturnPolicyDescriptionType: "Days",
      ProductReviews: [
        {
          ReviewId: 1,
          Name: "Sonal",
          Email: "",
          Rating: 5,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
        {
          ReviewId: 2,
          Name: "Viraj",
          Email: "",
          Rating: 1,
          Date: "2021-08-01",
          Review:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
        },
        {
          ReviewId: 3,
          Name: "Ramendra ",
          Email: "",
          Rating: 4,
          Date: "2021-08-01",
          Review:
            "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        },
      ],
    },
  ];
  const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  useEffect(()=>{
    const fetchData = async () => {
      try {
          const response = await axios.get('https://swadeshi-bazaar-backend.vercel.app/products/');
          setData(response.data);
          console.log(data);
          setLoading(false);
      } catch (err) {
          setError(err);
          setLoading(false);
      }
  };
  fetchData();
  },[])
  return (
    <div className="allproducts">
      <h1>All Products</h1>
      {loading && 
    <div>loading...</div>
      }
      {!loading && 
      <div className="products">
        {products.map((item, index) => {
          return <ProductCard data={item} key={index} />;
        })}
      </div>
}
    </div>
  );
};

export default AllProducts;
